import styled from '@emotion/styled'
import { Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'


const Container = styled.div`
  display: flex;
  flex-direction: column;
`

/**
 * Controlled Checkbox list.
 */
const ControlledCheckboxes = ({ control, rules, name, items }) => {
  const handleSelect = (e, selectedValues) => {
    if (e.target.checked) {
      return [...selectedValues, e.target.value]
    }
    return selectedValues.filter((value) => value !== e.target.value)
  }

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field }) => (
        <Container>
          {items.map((item) => {
            const { value, label } = item
            return (
              <Checkbox
                key={`${value}_${label}`}
                label={label}
                value={value}
                checked={field.value.includes(value)}
                onChange={(e) => {
                  field.onChange(handleSelect(e, field.value))
                }}
              />
            )
          })}
        </Container>
      )}
    />
  )
}

ControlledCheckboxes.propTypes = {
  /**
   * Control from useForm of react-hooks-form
   */
  control: PropTypes.any.isRequired,
  /**
   * Rules to config the controller.
   */
  rules: PropTypes.object,
  /**
   * Name of the attribute in the form. Required by `react-hook-form`.
   */
  name: PropTypes.string.isRequired,
  /**
   * Array of choices.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default ControlledCheckboxes
