import styled from "@emotion/styled";
import {
  playerValueKeys,
  usePlayersContext,
} from "../../../../providers/PlayersProvider";
import PlayerScore from "./PlayerScore/PlayerScore";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  // Pour le navigateur de IOS avec les barres en bas et en haut.
  max-height: 80vh;
  overflow: auto;
  @media (min-width: 600px) {
    max-height: 100%;
  }
`;

const StyledPlayerScoreList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PlayerScoreList = ({ players, updatePlayers }) => {
  const { savePlayers } = usePlayersContext();
  const addPoint = (e) => {
    const updatedPlayers = {
      ...players,
      [e.target.name]: {
        ...players[e.target.name],
        count: players[e.target.name].count + 1,
      },
    };
    updatePlayers(updatedPlayers);
    savePlayers(updatedPlayers, [playerValueKeys.COUNT]);
  };

  const removePoint = (e) => {
    const updatedPlayers = {
      ...players,
      [e.target.name]: {
        ...players[e.target.name],
        count: players[e.target.name].count - 1,
      },
    };
    updatePlayers(updatedPlayers);
    savePlayers(updatedPlayers, [playerValueKeys.COUNT]);
  };

  return (
    <Container>
      <StyledPlayerScoreList>
        {Object.values(players).map((player) => {
          return (
            <PlayerScore
              key={player.id}
              player={player}
              addPoint={addPoint}
              removePoint={removePoint}
            />
          );
        })}
      </StyledPlayerScoreList>
    </Container>
  );
};

export default PlayerScoreList;
