import PropTypes from 'prop-types'
import { FormControlLabel } from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  gap: 16px;
`

/**
 * Controlled radio group.
 * @param {any} control,
 * @param {Object} rules,
 * @param {String} name,
 * @param {Array} items
 * @returns
 */
const ControlledRadios = ({ control, rules, name, items }) => {
  const radios = items.map((item) => {
    const { value, label } = item
    return (
      <FormControlLabel
        key={`${value}_${label}`}
        value={value}
        control={<Radio />}
        label={label}
      />
    )
  })

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field }) => (
        <StyledRadioGroup {...field}>{radios}</StyledRadioGroup>
      )}
    />
  )
}

ControlledRadios.propTypes = {
  /**
   * Control from useForm of react-hooks-form
   */
  control: PropTypes.any.isRequired,
  /**
   * Rules to config the controller.
   */
  rules: PropTypes.object,
  /**
   * Name of the attribute in the form. Required by `react-hook-form`.
   */
  name: PropTypes.string.isRequired,
  /**
   * Array of choices.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    })
  ).isRequired,
}

export default ControlledRadios
