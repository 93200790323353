import styled from "@emotion/styled";
import { Button as MuiButton } from "@mui/material";

const StyledButton = styled(MuiButton)`
    height: 64px;
    font-size: 1em;
    border-radius: 24px;
`;

const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default Button;
