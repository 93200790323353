import { PlayersProvider } from "./providers";
import AppRoutes from "./routes";
import { AuthProvider } from "./services";

const App = () => {
  return (
    <AuthProvider>
      <PlayersProvider>
        <AppRoutes />
      </PlayersProvider>
    </AuthProvider>
  );
};

export default App;
