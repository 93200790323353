import styled from "@emotion/styled";
import { Divider, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Ads, Button } from "../../../atoms";
import { randomHex } from "../../../../utils";
import {
  initializedPlayer,
  playerValueKeys,
  usePlayersContext,
} from "../../../../providers/PlayersProvider";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  width: 100%;
  align-items: center;
`;

const StyledPlayerList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  padding: 8px 0;
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 64px;
    width: 100%;
    font-size: 1.2em;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  z-index: 2;
  width: 100%;
  margin-bottom: 24px;
  background-color: white;
`;

const ManagePlayersContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 32px;
`;

const StyledManageButton = styled(Button)`
  width: 80px;
`;

const StyledSubmitButton = styled(Button)`
  height: 64px;
  width: 100%;
  font-size: 1em;
`;

const PlayerList = ({ players, updatePlayers }) => {
  const { savePlayers } = usePlayersContext();
  const updatePlayerName = (e) =>
    updatePlayers({
      ...players,
      [e.target.name]: { ...players[e.target.name], name: e.target.value },
    });

  const addPlayer = () => {
    const random = randomHex(12);
    updatePlayers({
      ...players,
      [random]: { ...initializedPlayer, id: random },
    });
  };

  const removePlayer = () => {
    const playerIds = Object.keys(players);
    const lastPlayerIndex = playerIds.length - 1;
    const playerList = playerIds.reduce((acc, playerId, index) => {
      return index === lastPlayerIndex
        ? acc
        : { ...acc, [playerId]: players[playerId] };
    }, {});
    updatePlayers(playerList);
  };

  const onSubmit = () => {
    const updateDisplay = updatePlayers(players);
    savePlayers(players, [
      playerValueKeys.ID,
      playerValueKeys.NAME,
      playerValueKeys.COUNT,
    ]);
    updateDisplay(true);
  };

  const playerList = Object.keys(players).map((playerId) => {
    return (
      <StyledTextField
        placeholder="Pseudo du joueur..."
        key={playerId}
        name={playerId}
        onChange={updatePlayerName}
      />
    );
  });
  return (
    <Container>
      <Ads />
      <StyledPlayerList>{playerList}</StyledPlayerList>
      <ButtonContainer>
        <Divider style={{ width: "100%" }} />
        <ManagePlayersContainer>
          <StyledManageButton onClick={addPlayer}>
            <AddIcon />
          </StyledManageButton>
          <StyledManageButton onClick={removePlayer}>
            <RemoveIcon />
          </StyledManageButton>
        </ManagePlayersContainer>
        <StyledSubmitButton variant="contained" onClick={onSubmit}>
          Valider
        </StyledSubmitButton>
      </ButtonContainer>
    </Container>
  );
};

export default PlayerList;
