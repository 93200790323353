import { Route, Routes } from "react-router-dom";
import { Login, SignUp } from "../components/pages";
import AuthenticatedHome from "../components/pages/AuthenticatedHome/AuthenticatedHome";
import ResetPassword from "../components/pages/ResetPassword/ResetPassword";

import StandardHome from "../components/pages/StandardHome/StandardHome";
import ProtectedRoute from "./ProtectedRoute";
import UnprotectedRoute from "./UnprotectedRoute/UnprotectedRoute";

const AppRoutes = () => {
  return (
      <Routes>
        <Route
          exact
          path="/home"
          element={
            <ProtectedRoute redirect="/login" isAuthRedirect="/" scope="member">
              <AuthenticatedHome />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/"
          exact
          element={
            <UnprotectedRoute redirect="/home">
              <StandardHome />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <UnprotectedRoute redirect="/">
              <Login />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <UnprotectedRoute redirect="/">
              <SignUp />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <UnprotectedRoute redirect="/">
              <ResetPassword />
            </UnprotectedRoute>
          }
        />
      </Routes>
  );
};

export default AppRoutes;
