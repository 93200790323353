// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth";

const {REACT_APP_API_KEY, REACT_APP_MESSENGER_ID,REACT_APP_APP_ID,REACT_APP_MEASURE_ID, REACT_APP_DATABASE_URL} = process.env

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: REACT_APP_API_KEY,

  authDomain: "battlestar-347.firebaseapp.com",

  databaseURL: REACT_APP_DATABASE_URL,

  projectId: "battlestar-347",

  storageBucket: "battlestar-347.appspot.com",

  messagingSenderId: REACT_APP_MESSENGER_ID,

  appId: REACT_APP_APP_ID,

  measurementId: REACT_APP_MEASURE_ID

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);


export const config = {
    app,
    analytics,
    auth,
    db,
    firebase: firebaseConfig,
}