import { signInWithRedirect } from "firebase/auth";
import { config } from "../../../firebaseConfig";
import { googleProvider } from "./config";
import { getResult } from "./getResult";

const {auth} = config



export const signWithRedirect = () => {
    signInWithRedirect(auth, googleProvider);
    getResult()
}