import styled from "@emotion/styled"

const StyledLayout = styled.div`
    margin: 12px;
    height: calc(100vh - 24px);
    width: calc(100vw - 24px);
`

const Layout = ({children}) => {
    return <StyledLayout>{children}</StyledLayout>
}

export default Layout