import { Button, Header } from "../../atoms";
import { signOut } from "../../../services";
import { Layout } from "../../templates";
import {
  initializedPlayer,
  usePlayersContext,
} from "../../../providers/PlayersProvider";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import PlayerList from "../StandardHome/PlayerList/PlayerList";
import PlayerScoreList from "../StandardHome/PlayerScoreList/PlayerScoreList";

const StyledAuthenticatedHome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & button {
    margin-top: 16px;
  }
`;

const AuthenticatedHome = () => {

  const { players, setPlayers } = usePlayersContext();
  const [display, setDisplay] = useState(
    !!Object.keys(players).filter((player) => !!players[player].name).length
  );

  const playerWereDefined = !!localStorage.getItem("playerNumber");

  useEffect(() => {
    if (playerWereDefined) setDisplay(true);
  }, [playerWereDefined]);

  const updatePlayers = (playerList) => {
    setPlayers(playerList);
    return (displayValue = display) => setDisplay(displayValue);
  };

  const onReset = () => {
    localStorage.clear();
    const updateDisplay = updatePlayers({
      firstPlayer: { ...initializedPlayer, id: "firstPlayer" },
      secondPlayer: { ...initializedPlayer, id: "secondPlayer" },
    });
    updateDisplay(false);
  };

  return (
    <StyledAuthenticatedHome>
      <Layout>
        <Header title="Battle Star">
          {display && <Button onClick={onReset}>Reset</Button>}
          {!display && (
            <Button variant="standard" onClick={() => signOut()}>
              Logout
            </Button>
          )}
        </Header>
        {/* <p style={{marginTop: 0}}>Nombre de joueurs: {Object.keys(players).length}</p> */}
        <Divider />
        {!display && (
          <PlayerList players={players} updatePlayers={updatePlayers} />
        )}
        {display && (
          <PlayerScoreList players={players} updatePlayers={updatePlayers} />
        )}
      </Layout>
    </StyledAuthenticatedHome>
  );
};

export default AuthenticatedHome;
