import React from 'react'
import PropTypes from 'prop-types'

import { Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'


import useControl from '../useControl'
import styled from '@emotion/styled'
import { Select } from '@mui/material'

const StyledStaffSelect = styled(Select)`
  height: 36px;
  margin-bottom: 4px;

  background-color: transparent;
  color: ${({ theme }) => theme.palette.text.primary};

  & .MuiSelect-icon {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

const StyledInputLabel = styled(InputLabel)`
  font-size: 0.95em;
`

/**
 * Select UI wrapped in the `react-hook-form` Controller.
 * Pass all other props to the Select base component.
 */
const ControlledSelect = ({
  control: _control,
  defaultValue,
  label,
  name,
  ...props
}) => {
  const control = useControl({ control: _control })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { value, onChange, ref, ...otherFields } }) => (
        <FormControl variant="outlined">
          {label && value && (
            <StyledInputLabel id="select-input-label" shrink>
              {label}
            </StyledInputLabel>
          )}
          <StyledStaffSelect
            inputRef={ref}
            labelId="select-input-label"
            label={label && value ? label : undefined}
            value={value}
            onChange={onChange}
            {...props}
            {...otherFields}
          />
        </FormControl>
      )}
    />
  )
}

ControlledSelect.propTypes = {
  /**
   * Control object from `react-hook-form`.
   */
  control: PropTypes.any,
  /**
   * Default value to inject in the `react-hook-form` Controller.
   */
  defaultValue: PropTypes.string,
  /**
   * Message displayed on top of the Select.
   */
  label: PropTypes.string,
  /**
   * Name of the attribute in the form. Required by `react-hook-form`.
   */
  name: PropTypes.string.isRequired,
}

export default ControlledSelect
