import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'

import { Controller } from 'react-hook-form'
import Grid from '@mui/material/Grid'

import useControl from '../useControl'
import styled from '@emotion/styled'

const NumberBuilderTextField = styled(TextField)`
  & input {
    padding-top: 6.5px;
  }
`

/**
 * Displays multiple 'number' TextFields.
 */
const ControlledNumberTextFields = ({
  control: _control,
  label,
  textFields = [],
}) => {
  const control = useControl({ control: _control })

  return (
    <div>
      {label && <h6>{label}</h6>}
      <Grid container spacing={1} alignItems="center" wrap="nowrap">
        {textFields.map((field) => (
          <Controller
            key={field.name}
            control={control}
            defaultValue={field.defaultValue}
            name={field.name}
            render={({ field: { ref, value, onChange, ...otherFields } }) => (
              <>
                {field.label && (
                  <Grid item>
                    <h6>{field.label}</h6>
                  </Grid>
                )}
                <Grid item>
                  <NumberBuilderTextField
                    withoutModal
                    inputRef={ref}
                    type="number"
                    size="small"
                    value={value}
                    onChange={onChange}
                    {...otherFields}
                  />
                </Grid>
              </>
            )}
          />
        ))}
      </Grid>
    </div>
  )
}

ControlledNumberTextFields.propTypes = {
  /**
   * Control object from `react-hooks-form`.
   */
  control: PropTypes.any,
  /**
   * Text to display on top of the fields.
   */
  label: PropTypes.string,
  /**
   * Props to pass to each TextField.
   * See 'components/TextFields/variants/BuilderTextField' for more details.
   */
  textFields: PropTypes.arrayOf(
    PropTypes.shape({
      defaultValue: PropTypes.any.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      name: PropTypes.string.isRequired,
    })
  ),
}

export default ControlledNumberTextFields
