import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../services";


/**
 * Route which need to be authenticated.
 * If a user tries to reach this route without an authToken, he will be redirected to the `redirect` url.
 * In this case, the router state stores the location he wanted to access in order to redirect him after connection.
 */
const ProtectedRoute = ({
  children,
  redirect,
  isAuthRedirect = "/",
  scope,
  ...props
}) => {
  const { isAuth, userCan } = useAuthContext();
  const getComponent = () => {
    if (!isAuth) return <Navigate replace to={{ pathname: redirect }} />;

    return !userCan(scope) ? (
      <Navigate replace to={{ pathname: isAuthRedirect }} />
    ) : (
      children
    );
  };
  return <div {...props}>{getComponent()}</div>;
};

ProtectedRoute.propTypes = {
  /**
   * Redirection url if the user is not authenticated.
   */
  redirect: PropTypes.string.isRequired,

  /**
   * Redirection url if the user is authenticated and hasn't right permission to go on page.
   */
  isAuthRedirect: ({ scope, isAuthRedirect }, propName) =>
    scope && !isAuthRedirect
      ? new Error(`To redirect an user when he doesn't have right permissions to see a page.
    You miss to pass a prop: ${propName}`)
      : null,
  /**
   * scope
   */
  scope: ({ scope, isAuthRedirect }, propName) =>
    !scope && isAuthRedirect
      ? new Error(`To redirect an user when he doesn't have right permissions to see a page.
  You miss to pass a prop: ${propName}`)
      : null,
};

export default ProtectedRoute;
