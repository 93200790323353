import styled from "@emotion/styled";
import { Button } from "../../../../atoms";

const ButtonContainer = styled.div`
  display: flex;
  gap: 32px;
`;

const ScoreButton = styled(Button)`
  width: 96px;
  height: 96px;
  font-size: 2em;
`;

const PlayerScore = ({ player, addPoint, removePoint }) => {
  return (
    <div key={player.id}>
      <h3>
        {player.name}: {player.count}
      </h3>
      <ButtonContainer>
        <ScoreButton name={player.id} onClick={removePoint} variant="contained">
          -1
        </ScoreButton>
        <ScoreButton name={player.id} onClick={addPoint} variant="contained">
          +1
        </ScoreButton>
      </ButtonContainer>
    </div>
  );
};


export default PlayerScore