import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Layout } from "../../templates";
import PlayerList from "./PlayerList/PlayerList";
import PlayerScoreList from "./PlayerScoreList/PlayerScoreList";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button, Header } from "../../atoms";
import {
  initializedPlayer,
  usePlayersContext,
} from "../../../providers/PlayersProvider";

const StyledStandardHome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & button {
    margin-top: 16px;
  }
`;

const StandardHome = () => {
  const navigate = useNavigate();

  const { players, setPlayers } = usePlayersContext();
  const [display, setDisplay] = useState(
    !!Object.keys(players).filter((player) => !!players[player].name).length
  );

  const playerWereDefined = !!localStorage.getItem("playerNumber");

  useEffect(() => {
    if (playerWereDefined) setDisplay(true);
  }, [playerWereDefined]);

  const updatePlayers = (playerList) => {
    setPlayers(playerList);
    return (displayValue = display) => setDisplay(displayValue);
  };

  const onReset = () => {
    localStorage.clear();
    const updateDisplay = updatePlayers({
      firstPlayer: { ...initializedPlayer, id: "firstPlayer" },
      secondPlayer: { ...initializedPlayer, id: "secondPlayer" },
    });
    updateDisplay(false);
  };

  return (
    <StyledStandardHome>
      <Layout>
        <Header title='Battle Star'>
          {display && <Button onClick={onReset}>Reset</Button>}
          {!display && (
            <Button onClick={() => navigate("/login")}>Login</Button>
          )}
        </Header>
        {/* <p style={{marginTop: 0}}>Nombre de joueurs: {Object.keys(players).length}</p> */}
        <Divider />
        {!display && (
          <PlayerList players={players} updatePlayers={updatePlayers} />
        )}
        {display && (
          <PlayerScoreList players={players} updatePlayers={updatePlayers} />
        )}
      </Layout>
    </StyledStandardHome>
  );
};

export default StandardHome;
