import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import useControl from '../useControl'
import { TextField } from '@mui/material'

/**
 * Select UI wrapped in the `react-hook-form` Controller.
 * Handle the localization if the props `locale` is given.
 * Pass all other props to the TextField base component.
 */
const ControlledTextField = ({
  control: _control,
  defaultValue = '',
  label,
  locale,
  name,
  rules,
  disabled,
  ...props
}) => {
  const control = useControl({ control: _control })
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({
        field: { ref, value, onChange, ...otherFields },
        fieldState: { error },
      }) => (
        <TextField
          label={label}
          size="medium"
          value={value}
          error={!!error}
          disabled={disabled}
          onChange={onChange}
          {...otherFields}
          {...props}
        />
      )}
    />
  )
}

ControlledTextField.propTypes = {
  /**
   * Control object from `react-hooks-form`.
   */
  control: PropTypes.any,
  /**
   * Default value to provide to the `react-hooks-form` Controller.
   */
  defaultValue: PropTypes.string,
  /**
   * Message displayed on top of the TextField.
   */
  label: PropTypes.string.isRequired,
  /**
   * Locale used to display and update the right texts based on language.
   */
  locale: PropTypes.string,
  /**
   * Name of the attribute in the form. Required by `react-hook-form`.
   */
  name: PropTypes.string.isRequired,
  /**
   * Disable the text field so that its value cannot be updated
   */
  disabled: PropTypes.bool,
}

export default ControlledTextField
