import { signOut as firebaseSignOut } from "firebase/auth";
import { config } from "../firebaseConfig";

const { auth }= config 

export const signOut = () => {
    firebaseSignOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
}