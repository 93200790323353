import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../services";

/**
 * Route which redirects if the user is authenticated.
 * If a user tries to reach this route with an authToken, he will be redirected to the `redirect` url.
 */
const UnprotectedRoute = ({ children, redirect, ...props }) => {
  const { isAuth } = useAuthContext();
  const component = isAuth ? (
    <Navigate replace to={redirect} {...props} />
  ) : (
    children
  );
  return <div>{component}</div>;
};

UnprotectedRoute.propTypes = {
  /**
   * Redirection url if the user is authenticated.
   */
  redirect: PropTypes.string.isRequired,
};

export default UnprotectedRoute;
