import styled from "@emotion/styled";
import { Button } from "../../atoms";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../../services/firebase/auth/signUp";
import { ControlledTextField } from "../../atoms/Controlled";
import { Layout } from "../../templates";

const formKeys = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  password: "password",
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SignUp = () => {
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    signUp(data.email, data.password);
  };

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <h1>S'inscrire</h1>
          <Button onClick={() => navigate("/login")}>Connexion</Button>
        </TitleContainer>

        <ControlledTextField
          name={formKeys.firstName}
          control={control}
          label={formKeys.firstName}
        />
        <ControlledTextField
          name={formKeys.lastName}
          control={control}
          label={formKeys.lastName}
        />
        <ControlledTextField
          name={formKeys.email}
          control={control}
          label={formKeys.email}
        />
        <ControlledTextField
          name={formKeys.password}
          control={control}
          label={formKeys.password}
        />
        <Button onClick={handleSubmit(onSubmit)} variant="contained">
          Sign up
        </Button>
      </Container>
    </Layout>
  );
};

export default SignUp;
