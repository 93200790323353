import { createContext, useContext, useEffect, useState } from "react";

const PlayersContext = createContext();
export const usePlayersContext = () => useContext(PlayersContext);

const firstPlayer = "firstPlayer";
const secondPlayer = "secondPlayer";

export const initializedPlayer = { id: "", name: "", count: 0 };

export const playerValueKeys = {
  ID: "id",
  NAME: "name",
  COUNT: "count",
};

const getSavedPlayers = (setPlayers) => {
  const playerNumber = localStorage.getItem("playerNumber");
  if (!playerNumber) return;
  const players = {};
  for (let i = 0; i < playerNumber; i++) {
    const player = {
      name: localStorage.getItem(`player${i}_name`),
      id: localStorage.getItem(`player${i}_id`),
      count: localStorage.getItem(`player${i}_count`),
    };
    players[player.id] = { ...player };
  }
  setPlayers(players);
};

const savePlayers = async (
  players,
  valueToSave = [
    playerValueKeys.ID,
    playerValueKeys.NAME,
    playerValueKeys.COUNT,
  ]
) => {
  const playerKeyList = Object.keys(players);
  
  localStorage.setItem("playerNumber", playerKeyList.length);
  playerKeyList.forEach((playerKey, i) => {
    valueToSave.forEach((key) => {
      localStorage.setItem(`player${i}_${key}`, players[playerKey][key]);
    });
  });
};

const PlayersProvider = ({ children }) => {
  const [players, setPlayers] = useState({
    firstPlayer: { ...initializedPlayer, id: firstPlayer },
    secondPlayer: { ...initializedPlayer, id: secondPlayer },
  });

  useEffect(() => {
    getSavedPlayers(setPlayers);
  }, []);

  return (
    <PlayersContext.Provider value={{ players, savePlayers, setPlayers }}>
      {children}
    </PlayersContext.Provider>
  );
};

export default PlayersProvider;
