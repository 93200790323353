import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Checkbox } from '@mui/material'

import useControl from '../useControl'
import styled from '@emotion/styled'

const StyledCheckbox = styled(Checkbox)`
  margin-left: -8px;
`

/**
 * Checkbox UI wrapped in the `react-hook-form` Controller.
 * Pass all other props to the Checkbox base component.
 */
const ControlledCheckbox = ({ control: _control, name, rules, ...props }) => {
  const control = useControl({ control: _control })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      rules={rules}
      render={({ field: { ref, value, onChange, ...otherFields } }) => (
        <StyledCheckbox
          checked={value}
          inputRef={ref}
          onChange={onChange}
          {...props}
          {...otherFields}
        />
      )}
    />
  )
}

ControlledCheckbox.propTypes = {
  /**
   * Control object from `react-hooks-form`.
   */
  control: PropTypes.any,
  /**
   * Name of the attribute in the form. Required by `react-hook-form`.
   */
  name: PropTypes.string.isRequired,
  /**
   * Rules to config the Controller.
   */
  rules: PropTypes.object,
}

export default ControlledCheckbox
