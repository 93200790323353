import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { config } from "../firebaseConfig";

const { auth } = config;
export const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const userCan = (scope) => {
    const role = user.role || "member";
    return role === "admin" || role === scope;
  };

  useEffect(() => {
    onAuthStateChanged(auth, (signedUser) => {
      if (signedUser) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        if (!user || user.uid !== signedUser.uid) {
          setUser(signedUser);
          localStorage.setItem("user", JSON.stringify(user));
        }
      } else {
        setUser(null);
      }
    });
  }, [user]);
  return (
    <AuthContext.Provider
      value={{ user, isAuth: !!user && !!user.uid, userCan }}
    >
      {children}
    </AuthContext.Provider>
  );
};