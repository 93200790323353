import { sendPasswordResetEmail } from "firebase/auth";
import { config } from "../firebaseConfig";

const { auth } = config;

export const changePasswordEmail = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      // ..
    })
    .catch((error) => {
      console.error(error);
    });
};
