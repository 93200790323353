import { createUserWithEmailAndPassword } from "firebase/auth";
import { config } from "../firebaseConfig";

const {auth} = config

export const signUp = (email, password) => {
 createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log("🚀 line 11 ~ .then ~ user: ", user)
    
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log('errorCode: ', errorCode)
    console.log('errorMessage: ', errorMessage)
  });   
} 
