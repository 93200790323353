import styled from "@emotion/styled";
import { Button } from "../../atoms";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { changePasswordEmail } from "../../../services/firebase/auth/changePasswordEmail";
import { ControlledTextField } from "../../atoms/Controlled";
import { Layout } from "../../templates";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ResetPassword = () => {
    const navigate = useNavigate()
  const { control, handleSubmit } = useForm({
    email: "",
  });

  const onSubmit = (data) => {
    changePasswordEmail(data.email);
  };

  return (
    <Layout>
      <Container>
      <TitleContainer>
          <h1>Changer votre mot de passe</h1>
          <Button onClick={() => navigate("/login")}>Connexion</Button>
        </TitleContainer>
        <ControlledTextField
          name="email"
          label="email"
          control={control}
          rules={{ required: true }}
        />
        <Button onClick={handleSubmit(onSubmit)} variant='contained'>
          Valider
        </Button>
      </Container>
    </Layout>
  );
};

export default ResetPassword;
