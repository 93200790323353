import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Header = ({title, children}) => {
return (
    <Container>
        <h1>{title}</h1>
        {children}
    </Container>
)
}


export default Header