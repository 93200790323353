import { signInWithEmailAndPassword } from "firebase/auth";
import { config } from "../../firebaseConfig";
import { signWithPopUp, signWithRedirect } from "./google";

const { auth } = config;

export const templates = {
  DEFAULT: "default",
  GOOGLE_REDIRECT: "googleRedirect",
  GOOGLE_POP_UP: "googlePopUp",
};

export const signIn = (template, authOptions) => {
  const options = { ...authOptions };
  if (template === templates.DEFAULT)
    signInWithEmailAndPassword(auth, options.email, options.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user)

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });
if(template === templates.GOOGLE_REDIRECT)
    signWithRedirect()
    if(template === templates.GOOGLE_POP_UP)
    signWithPopUp()
};
