import { Button } from "../../atoms";
import { useForm } from "react-hook-form";
import { signIn, templates } from "../../../services";
import { ControlledTextField } from "../../atoms/Controlled";
import { Layout } from "../../templates";
import GoogleIcon from "@mui/icons-material/Google";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Login = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    const { email, password } = data;
    signIn(templates.DEFAULT, { email, password });
  };

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <h1>Login Page</h1>
          {<Button onClick={() => navigate("/")}>Retour</Button>}
        </TitleContainer>

        <ControlledTextField
          control={control}
          name="email"
          label="email"
          variant="standard"
        />
        <ControlledTextField
          control={control}
          name="password"
          label="password"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                 <Button onClick={() => navigate("/resetpassword")}> oublié ? </Button>
              </InputAdornment>
            ),
          }}
        />
        <Button onClick={handleSubmit(onSubmit)} variant="contained">
          Login
        </Button>
        <Button
          onClick={() => {
            signIn(templates.GOOGLE_REDIRECT);
            
          }}
        >
          <GoogleIcon />
        </Button>
        <Button onClick={() => navigate("/signup")}>
          Pas encore de compte ? Inscrivez-vous
        </Button>
      </Container>
    </Layout>
  );
};

export default Login;
