import { getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import { config } from "../../../firebaseConfig";


const { auth} = config
export const getResult = () => {
    getRedirectResult(auth)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access Google APIs.
    GoogleAuthProvider.credentialFromResult(result);

  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    console.log("🚀 ~ file: getResult.js ~ line 15 ~ .then ~ errorCode", errorCode)
    const errorMessage = error.message;
    console.log("🚀 ~ file: getResult.js ~ line 17 ~ .then ~ errorMessage", errorMessage)
    // The email of the user's account used.
    const email = error.email;
    console.log("🚀 ~ file: getResult.js ~ line 20 ~ .then ~ email", email)
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log("🚀 ~ file: getResult.js ~ line 23 ~ .then ~ credential", credential)
    // ...
  });
}