import { useFormContext } from 'react-hook-form'

/**
 * If no `control` is provided by props, use the `control` object from the `react-hook-form`
 * Form Context if available.
 */
const useControl = ({ control }) => {
  const formContext = useFormContext()

  return control || (formContext && formContext.control)
}

export default useControl
