import { useEffect } from "react";

const Ads = () => {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }, []);
  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: "block", width: "100%", height: 90 }}
        data-ad-client="ca-pub-5525585750441306"
        data-ad-slot="5525585750441306"
        data-ad-format="auto"
      />
    </div>
  );
};

export default Ads;
